import React, { useState, useEffect } from 'react';

import { navigate } from 'gatsby';

import styled from '@emotion/styled';
import { tabletQuery, laptopQuery, desktopQuery } from '../utils/mediaqueries';

import { useSwipeable } from 'react-swipeable';
import { isInStandaloneMode, createLink } from '../utils/functions';

import Drawer from '../components/drawer/Drawer';
import Header from '../components/Header';
import Layout from '../components/layout/Layout';
import NavExcerpt from '../components/NavExcerpt';
import NavMenu from '../components/NavMenu';
import Seo from '../components/Seo';
import UniversityBigText from '../components/UniversityBigText';
import { mainPageDescriptions } from '../seo/MainPageSeo';
import DownloadBanner from '../components/downloadBanner/DownloadBanner';
import { GatsbyImage } from 'gatsby-plugin-image';

const MainWrapper = styled.div({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
});

const tabletViewportHeight = isInStandaloneMode() ? '100vh' : '93vh';
const mobileViewportHeight = isInStandaloneMode() ? '100vh' : '91vh';

const ImageWrapper = styled.div(
  {
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    position: 'fixed',
    top: 0,
    width: '100%',

    [laptopQuery]: {
      width: '67%',
      right: 0,
    },

    [desktopQuery]: {
      width: '67%',
      right: 0,
    },
  },
  ({ src }) => ({
    backgroundImage: `url(${src})`,
  }),
);

const ExcerptWrapper = styled.div({
  display: 'block',
  marginBottom: '55px',
  marginTop: mobileViewportHeight,
  zIndex: 0,

  [tabletQuery]: {
    marginTop: `calc(${tabletViewportHeight} - 88px)`,
  },

  [laptopQuery]: {
    display: 'flex',
    flexDirection: 'column',
    width: '26.5%',
    margin: 0,
    marginLeft: '6.5%',
  },

  [desktopQuery]: {
    display: 'flex',
    flexDirection: 'column',
    width: '26.5%',
    margin: 0,
    marginLeft: '6.5%',
  },
});

const UpperTagline = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 1,
});

const MainPageTemplate = ({ pageContext, uri }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const navOptions = ['/', '/studium', '/mesto', '/navstiv-nas'];

  const { backgroundImage, claimLowerText, claimUpperText, navExcerpts, title } = pageContext;

  useEffect(() => {
    if (uri === '/univerzita') {
      setCurrentIndex(0);
    }

    if (uri === '/studium') {
      setCurrentIndex(1);
    }

    if (uri === '/mesto') {
      setCurrentIndex(2);
    }

    if (uri === '/navstiv-nas') {
      setCurrentIndex(3);
    }
  }, [uri]);

  const getSeoDescription = () => {
    switch (uri) {
      case '/':
        return mainPageDescriptions.univerzita;

      case '/univerzita':
        return mainPageDescriptions.univerzita;

      case '/studium':
        return mainPageDescriptions.studium;

      case '/mesto':
        return mainPageDescriptions.mesto;

      case '/navstiv-nas':
        return mainPageDescriptions.navstivNas;

      default:
        return '';
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => navigate(navOptions[currentIndex + 1]),
    onSwipedRight: () => {
      if (currentIndex === 0) {
        navigate(navOptions[3]);
      } else {
        navigate(navOptions[currentIndex - 1]);
      }
    },
    preventDefaultTouchmoveEvent: true,
  });

  const renderExcerpts = navExcerpts.map(({ heading, description, thumbnail }) => (
    <NavExcerpt
      key={heading}
      heading={heading}
      description={description}
      thumbnail={thumbnail}
      to={`${uri}/${createLink(heading)}/`}
      state={{ excerpts: navExcerpts }}
    />
  ));

  const metaDescription = getSeoDescription();

  return (
    <>
      <Seo title={title} description={metaDescription} image={backgroundImage} />
      <div {...handlers}>
        <Layout>
          <MainWrapper>
            <ImageWrapper>
              <UpperTagline>
                <Header displayDesktop />
                <UniversityBigText firstHeadingText={claimUpperText} secondHeadingText={claimLowerText} />
              </UpperTagline>
              <GatsbyImage image={backgroundImage.childImageSharp.gatsbyImageData} style={{ flex: 1 }} />
              <DownloadBanner />
            </ImageWrapper>
            <ExcerptWrapper>
              <Drawer />
              {renderExcerpts}
            </ExcerptWrapper>
            <NavMenu />
          </MainWrapper>
        </Layout>
      </div>
    </>
  );
};

export default MainPageTemplate;
