import styled from '@emotion/styled';
import { laptopToDesktopQuery } from '../../utils/mediaqueries';
import theme from '../../utils/theme';
import Icon from '../Icon';

export const Container = styled.div({
  display: 'flex',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',

  [laptopToDesktopQuery]: {
    display: 'none',
  },
});

export const StyledIcon = styled(Icon)({
  background: theme.colors.orangeBackground,
  borderTopRightRadius: '25px',
  borderTopLeftRadius: '25px',
  padding: '2px 25px',
  color: theme.colors.darkBlue,
});
