import React, { useState } from 'react';
import Icon from '../Icon';
import ExcerptParagraph from '../ExcerptParagraph';
import { isIos, isInStandaloneMode, isMobile } from '../../utils/functions';

import { Container } from './styles';
import theme from '../../utils/theme';

const DownloadBanner = () => {
  const [isShown, setIsShown] = useState(true);
  const showBanner = isIos() && isMobile() && !isInStandaloneMode();

  return (
    <>
      {showBanner && isShown && (
        <Container onClick={() => setIsShown(false)}>
          <Icon iconName="share-square" color={theme.colors.orangeBackground} size="1x" />
          <ExcerptParagraph color="white" marginLeft="10px">
            Stáhni si tuhle appku stisknutím "Sdílet" a pak "Přidat na plochu".
          </ExcerptParagraph>
        </Container>
      )}
    </>
  );
};

export default DownloadBanner;
