import React from 'react';

import { Container, StyledIcon } from './styles';

const Drawer = () => (
  <Container>
    <StyledIcon iconName="bars" size="1x" />
  </Container>
);

export default Drawer;
