import styled from '@emotion/styled';
import theme from '../../utils/theme';

export const Container = styled.div({
  alignItems: 'center',
  height: '60px',
  display: 'flex',
  width: '85%',
  padding: '10px 15px',
  borderRadius: '5px',
  backgroundColor: theme.colors.blue,
  marginTop: 'auto',
  marginBottom: '80px',
  alignSelf: 'center',
});
