import styled from '@emotion/styled';

import { mobileQuery, tabletQuery, desktopQuery } from '../utils/mediaqueries';

// TODO: Rename this to Paragraph?
const ExcerptParagraph = styled.p(
  {
    margin: '0',
    lineHeight: '1.4em',

    [mobileQuery]: {
      height: '80%',
      overflow: 'auto',
    },

    [tabletQuery]: {
      fontSize: '1.2rem',
    },

    [desktopQuery]: {
      fontSize: '1.3rem',
    },
  },
  props => ({
    ...props,
  }),
);

export default ExcerptParagraph;
