import React from 'react';

import styled from '@emotion/styled';
import theme from '../utils/theme';
import {
  mobileQuery,
  laptopQuery,
  tabletQuery,
  desktopQuery,
} from '../utils/mediaqueries';

const UniversityBigTextWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  background: theme.colors.orangeBackground,

  [mobileQuery]: {
    marginLeft: '20px',
    width: '60%',
    padding: '8px',
  },

  [tabletQuery]: {
    marginLeft: '35px',
    padding: '20px',
    width: '370px',
  },

  [laptopQuery]: {
    width: '260px',
    padding: '20px',
    marginLeft: '55px',
  },

  [desktopQuery]: {
    width: '300px',
    padding: '20px',
    marginLeft: '72px',
  },
});

const FirstText = styled.p({
  color: theme.colors.mainText,
  margin: 0,
  fontWeight: 'bold',

  [mobileQuery]: {
    fontSize: '1.5rem',
  },

  [tabletQuery]: {
    fontSize: '3rem',
  },

  [laptopQuery]: {
    fontSize: '2rem',
  },

  [desktopQuery]: {
    fontSize: '2.5rem',
  },
});

const SecondText = styled.p({
  color: theme.colors.mainText,
  margin: 0,
  fontWeight: 'bold',

  [mobileQuery]: {
    fontWeight: 'bold',
    textAlign: 'left',
    fontSize: '3rem',
  },

  [tabletQuery]: {
    fontSize: '4rem',
  },

  [laptopQuery]: {
    fontSize: '4rem',
  },

  [desktopQuery]: {
    fontSize: '5rem',
  },
});

const UniversityBigText = ({ firstHeadingText, secondHeadingText }) => (
  <UniversityBigTextWrapper>
    <FirstText>{firstHeadingText}</FirstText>
    <SecondText>{secondHeadingText}</SecondText>
  </UniversityBigTextWrapper>
);

export default UniversityBigText;
